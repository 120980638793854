import React from "react"

const CTA = ({ text, buttonText, heading, link }) => {
  if (!text) {
    return null
  }
  return (
    <section className="image-bg overlay pt120 pb120 pt-xs-80 pb-xs-80">
      <div className="background-image-holder">
        <img
          alt="Background"
          className="background-image"
          src="img/vent4.jpg"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5 className="uppercase">
              <strong>{heading}</strong>
            </h5>
            <h4 className="mb160 mb-xs-80 bold">{text}</h4>
            <a className="btn btn-lg btn-filled" href={`./${link}`}>
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
